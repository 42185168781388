/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "The Village on Greenway",
  gtagId: "G-7XVTD56XFN",
  logo: {
    src: "https://cdn.maxxpm.com/villageongreenway/logosandbanners/nav_logo.png",
    alt: "The Village on Greenway Logo",
  },
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "12945 W Greenway Road",
    cityStateZip: "El Mirage, AZ 85335",
    phone: "(602) 830-4775",
    copyrightYear: "2024",
    logo: "https://cdn.maxxpm.com/villageongreenway/logosandbanners/footer_logo.png",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/villageongreenway/logosandbanners/leaves bullet point.png",
  hasCalendlyPopup: false,
  calendlyLink: undefined,
  home: {
    banner: {
      image: "https://cdn.maxxpm.com/villageongreenway/new exterior/_DSC0265.jpg",
      video: undefined,
      title: "A Higher Quality of Living",
      subtitle: "Available Now, Move-In Ready",
    },
    aboutUs: {
      paragraphs: [
        "We want to welcome you home to The Village on Greenway Apartments in El Mirage, Arizona! Our gated apartment community offers an array of amenities, such as a clubhouse, fitness center, and beautiful pool. Discover a new lifestyle located within minutes from Highway 60, and the El Mirage Community Center. Visit the Westgate Entertainment District nearby for an array of fun shops and unique restaurants or the Gila River Arena to see your favorite band in concert. The NFL’s Arizona Cardinals also play close by at State Farm Stadium, where you can see visit for a Football Game, Concert, or the Annual Fiesta Bowl!",
        "You will find that our apartments are what you are looking for with large 2-Bed, 2-Bath floorplans and a small array of 1 Bed 1 Bath studios. Just imagine yourself experiencing a better way of living at The Village on Greenway. We are excited for you to start your new adventure here! Join us now and call your new home at The Village on Greenway.",
      ],
      image: {
        src: "https://cdn.maxxpm.com/villageongreenway/new exterior/_DSC0278.jpg",
        alt: "The Village on Greenway Swimming Pool",
      },
    },
    floorplanTitle: "Explore Our Floor Plans and Take a 3D Virtual Tour",
    floorplans: [
      {
        name: "1-Bed 1-Bath",
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/villageongreenway/1bed1bath/1 Bedroom Layout.png",
          alt: "Village on Greenway 1-Bedroom Floorplan",
        },
        hasWalkthrough: false,
        walkthrough: undefined,
      },
      {
        name: "2-Bed 2-Bath",
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/villageongreenway/2bed2bath/styu-123-1.png",
          alt: "Village on Greenway 2-Bedroom Floorplan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=KF8YRtg4iNs",
          title: "2 bed 2 bath 3d walkthrough",
        },
      },
    ],
    midContainerImage: "https://cdn.maxxpm.com/lagunafarms/logoandbanners/yellow backpack hiker.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/villageongreenway/new exterior/_DSC0286.jpg",
        alt: "Village on Greenway fitness center",
      },
      {
        src: "https://cdn.maxxpm.com/villageongreenway/new exterior/_DSC0258.jpg",
        alt: "Village on Greenway playground",
      },
      {
        src: "https://cdn.maxxpm.com/villageongreenway/new exterior/_DSC0285.jpg",
        alt: "Village on Greenway clubhouse",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/stockphotos/man woman and dog.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
      breedRestrictions: [
        "Pit Bulls & Staffordshire Terriers",
        "Doberman Pinschers",
        "Rottweilers",
        "Chows",
        "Great Danes",
        "Presa Canarios/ Mastiffs",
        "Akitas",
        "Alaskan Malamutes",
        "Huskies",
        "German Shepherds",
        "Shar Peis",
        "Wolf-Hybrids",
      ],
    },
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/villageongreenway/new exterior/_DSC0265.jpg",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: false,
    waitingListJotformId: undefined,
    propertyGroup: "LEADS-VG",
  },
  faq: {
    banner: {
      image: "https://cdn.maxxpm.com/pineridge/pr_home.jpg",
    },
    dnaRequired: true,
  },
};
